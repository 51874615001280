import type { ReactNode } from "react";
import React, { createContext, useContext } from "react";
import { useSearchParamsManager } from "./SearchParamsManager.tsx";

export const SearchParamContext = createContext<undefined | URLSearchParams>(
	undefined,
);

export const SearchParamsKeeperProvider = ({
	searchParams = [],
	children,
}: {
	children?: ReactNode;
	searchParams?: string[];
}) => {
	const { setSearchParam, searchParams: _searchParams } =
		useSearchParamsManager()!;

	// for (const [key, value] of _searchParams.entries()) {
	//   if (!searchParams.includes(key)) {
	//     setSearchParam(key, undefined);
	//   }
	// }

	return (
		<SearchParamContext.Provider value={_searchParams}>
			{children}
		</SearchParamContext.Provider>
	);
};

export const useSearchParamsKeeper = () => {
	return useContext(SearchParamContext);
};
